import React, { useState } from 'react';
import { 
  Box, 
  Card, 
  Button, 
  Typography, 
  RadioGroup, 
  Radio, 
  FormControlLabel, 
  CircularProgress,
  Stack,
  Divider,
  useMediaQuery
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import CheckIcon from '@mui/icons-material/Check';
import axiosInstance from 'src/utils/axios';

const CustomPricingTable = ({ 
  availablePlans, 
  currentPlan = '', 
  onClose 
}) => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'));
  const [selectedPlan, setSelectedPlan] = useState(currentPlan);
  const [billingInterval, setBillingInterval] = useState('monthly');
  const [isLoading, setIsLoading] = useState(false);

  const handlePlanSelect = (planCode) => {
    setSelectedPlan(planCode);
  };

  // Helper function to format price display
  const formatPrice = (priceString) => {
    return priceString || 'N/A';
  };

  const handleSubscribe = async () => {
    if (!selectedPlan) return;
    
    // Find the selected plan
    const plan = availablePlans.find(p => p.plan_code === selectedPlan);
    if (!plan) return;
    
    // Find the pricing based on the selected plan and billing interval
    const pricing = plan.prices.find(p => p.payment_interval === billingInterval);
    if (!pricing) return;
    
    setIsLoading(true);
    try {
      // Create checkout session on your server
      const response = await axiosInstance.post('/subscriptions/create-checkout-session/', {
        plan_id: plan.id,
        payment_interval: billingInterval
      });
      
      // Redirect to Stripe Checkout
      window.location.href = response.data.checkout_url;
    } catch (error) {
      console.error('Error creating checkout session:', error);
      setIsLoading(false);
    }
  };

  return (
    <Box sx={{ p: 2 }}>
      <Typography variant="h4" sx={{ mb: 3, textAlign: 'center' }}>
        Choose Your Subscription Plan
      </Typography>
      
      <Box sx={{ mb: 3, display: 'flex', justifyContent: 'center' }}>
        <RadioGroup 
          value={billingInterval}
          onChange={(e) => setBillingInterval(e.target.value)}
          row
        >
          <FormControlLabel value="monthly" control={<Radio />} label="Monthly" />
          <FormControlLabel value="yearly" control={<Radio />} label="Yearly (Save 20%)" />
        </RadioGroup>
      </Box>
      
      <Stack 
        direction={isDesktop ? 'row' : 'column'} 
        spacing={2} 
        sx={{ mb: 4 }}
      >
        {availablePlans.map((plan) => {
          const pricing = plan.prices.find(p => p.payment_interval === billingInterval);
          const isRecommended = plan.is_recommended;
          const isSelected = selectedPlan === plan.plan_code;
          
          return (
            <Card 
              key={plan.id} 
              sx={{ 
                p: 3, 
                flex: 1,
                position: 'relative',
                cursor: 'pointer',
                border: isSelected ? `2px solid ${theme.palette.primary.main}` : '1px solid #e0e0e0',
                ...(isRecommended && {
                  borderColor: theme.palette.primary.main,
                  boxShadow: `0 0 0 2px ${theme.palette.primary.main}`,
                }),
                ...(isSelected && {
                  boxShadow: `0 0 0 2px ${theme.palette.primary.main}`,
                })
              }}
              onClick={() => handlePlanSelect(plan.plan_code)}
            >
              {isRecommended && (
                <Box
                  sx={{
                    position: 'absolute',
                    top: 12,
                    right: 12,
                    bgcolor: theme.palette.primary.main,
                    color: 'white',
                    py: 0.5,
                    px: 1,
                    borderRadius: 1,
                    fontSize: '0.75rem',
                  }}
                >
                  Recommended
                </Box>
              )}
              
              <Typography variant="h5" sx={{ mb: 1 }}>{plan.name}</Typography>
              
              <Typography variant="h3" sx={{ mb: 2, fontWeight: 700 }}>
                {pricing ? formatPrice(pricing.price) : 'N/A'}
                <Typography component="span" variant="body1" sx={{ ml: 0.5 }}>
                  {pricing && pricing.payment_interval === 'monthly' ? '/mo' : '/yr'}
                </Typography>
              </Typography>
              
              <Divider sx={{ my: 2 }} />
              
              <div dangerouslySetInnerHTML={{ __html: plan.features_html }} />
              
              <Button 
                variant={isSelected ? "contained" : "outlined"}
                fullWidth 
                sx={{ mt: 2 }}
                onClick={(e) => {
                  e.stopPropagation();
                  setSelectedPlan(plan.plan_code);
                  if (currentPlan !== plan.plan_code) {
                    handleSubscribe();
                  }
                }}
                disabled={isLoading || (currentPlan === plan.plan_code)}
              >
                {isLoading && selectedPlan === plan.plan_code ? (
                  <CircularProgress size={24} />
                ) : currentPlan === plan.plan_code ? (
                  'Current Plan'
                ) : (
                  'Select Plan'
                )}
              </Button>
            </Card>
          );
        })}
      </Stack>

      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Button 
          variant="outlined" 
          onClick={onClose}
        >
          Cancel
        </Button>
        
        <Button 
          variant="contained" 
          onClick={handleSubscribe}
          disabled={!selectedPlan || isLoading || (currentPlan === selectedPlan)}
        >
          {isLoading ? <CircularProgress size={24} /> : 'Confirm Selection'}
        </Button>
      </Box>
    </Box>
  );
};

export default CustomPricingTable; 