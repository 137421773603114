// react
import { useEffect, useState } from "react";

// mui
import {
  IconButton,
  Stack,
  Typography,
  FormControl,
  Radio,
  FormControlLabel,
  RadioGroup,
  Box,
  Divider,
  Link,
  useMediaQuery,
  Drawer,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useTheme } from "@mui/styles";

// icon
import CloseIcon from "@mui/icons-material/Close";

// hooks
import useAuth from "src/hooks/useAuth";
import useCollapseDrawer from "src/hooks/useCollapseDrawer";

// components
import SnackbarNotifier from "src/components/Snackbar";
import CustomPricingTable from './CustomPricingTable';

function VinuagePremium({
  subscriptionPlan,
  data,
  submit,
  interval,
  billingCycle,
}) {
  const {
    subscription: { available_plans },
  } = data;
  const plans = available_plans[1];
  const prices =
    billingCycle === "Monthly Billing" ? plans.prices[0] : plans.prices[1];
  const current_plan = subscriptionPlan === plans.plan_code;

  return (
    <Box
      sx={{
        border: "1px solid rgba(255, 255, 255, 0.4)",
        borderRadius: "4px",
        heigth: "32px",
        width: "100%",
      }}
    >
      <Box
        sx={{
          bgcolor: "rgba(255, 255, 255, 0.4)",
          p: "6px 8px 2px",
          borderRadius: "4px 4px 0 0",
        }}
      >
        <Typography
          textAlign={"center"}
          color={"black"}
          fontSize={16}
          fontWeight={400}
          lineHeight={"24px"}
        >
          Recommended
        </Typography>
      </Box>
      <Box p={2}>
        <Typography
          fontSize={20}
          fontFamily={"Minion Pro"}
          fontWeight={700}
          lineHeight={"24px"}
        >
          {plans.name}
        </Typography>
        <Typography
          fontSize={16}
          fontFamily={"Minion Pro"}
          fontWeight={700}
          lineHeight={"24px"}
        >
          {prices.price}/{billingCycle === "Monthly Billing" ? "month" : "year"}{" "}
          <span
            style={{
              fontSize: "12px",
              textDecoration: "line-through",
              color: "rgba(255, 255, 255, 0.6)",
            }}
          >
            {prices.price_original}
          </span>
        </Typography>
        <Box
          dangerouslySetInnerHTML={{ __html: plans.features_html }}
          sx={{ p: "16px", fontSize: "12px", color: "#F7E8CD" }}
        ></Box>
        {current_plan && interval ? (
          <LoadingButton
            variant="contained"
            fullWidth
            sx={{
              "&.Mui-disabled": { bgcolor: "rgba(255, 255, 255, 0.4)" },
              maxHeight: "40px",
              mt: 2,
            }}
            onClick={(e) =>
              submit({
                plan_code: "PREMIUM",
                payment_interval: billingCycle.split(" ")[0].toLowerCase(),
              })
            }
          >
            Change to {billingCycle}
          </LoadingButton>
        ) : (
          <LoadingButton
            variant="contained"
            disabled={current_plan}
            fullWidth
            sx={{
              "&.Mui-disabled": { bgcolor: "rgba(255, 255, 255, 0.4)" },
              maxHeight: "40px",
              mt: 2,
            }}
            onClick={(e) =>
              submit({
                plan_code: "PREMIUM",
                payment_interval:
                  billingCycle === "Monthly Billing" ? "monthly" : "yearly",
              })
            }
          >
            {current_plan ? "Current Plan" : "Upgrade To Premium"}
          </LoadingButton>
        )}
      </Box>
    </Box>
  );
}

function VinuageLite({
  subscriptionPlan,
  data,
  submit,
  interval,
  billingCycle,
}) {
  const {
    subscription: { available_plans },
  } = data;
  const plans = available_plans[0];
  const prices =
    billingCycle === "Monthly Billing" ? plans.prices[0] : plans.prices[1];

  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("sm"));
  const [currentPlan, setCurrentPlan] = useState(
    subscriptionPlan === plans.plan_code
  );

  return (
    <Box
      sx={{
        border: "1px solid rgba(255, 255, 255, 0.4)",
        borderRadius: "4px",
        p: 2,
        width: "100%",
      }}
      mt={1}
    >
      <Typography
        fontSize={20}
        fontFamily={"Minion Pro"}
        fontWeight={700}
        lineHeight={"24px"}
        mt={isDesktop ? 4.5 : ""}
      >
        {plans.name}
      </Typography>
      <Typography
        fontSize={16}
        fontFamily={"Minion Pro"}
        fontWeight={700}
        lineHeight={"24px"}
      >
        {prices.price}/{billingCycle === "Monthly Billing" ? "month" : "year"}{" "}
      </Typography>
      <Box
        dangerouslySetInnerHTML={{ __html: plans.features_html }}
        sx={{ p: "16px", fontSize: "12px", color: "#F7E8CD" }}
      ></Box>
      {currentPlan && interval ? (
        <LoadingButton
          variant="contained"
          fullWidth
          sx={{
            "&.Mui-disabled": { bgcolor: "rgba(255, 255, 255, 0.4)" },
            maxHeight: "40px",
            mt: 2,
          }}
          onClick={(e) =>
            submit({
              plan_code: "LITE",
              payment_interval: billingCycle.split(" ")[0].toLowerCase(),
            })
          }
        >
          Change To {billingCycle}
        </LoadingButton>
      ) : (
        <LoadingButton
          variant="contained"
          disabled={currentPlan}
          fullWidth
          sx={{
            "&.Mui-disabled": { bgcolor: "rgba(255, 255, 255, 0.4)" },
            maxHeight: "40px",
            mt: 2,
          }}
          onClick={(e) =>
            submit({
              plan_code: "LITE",
              payment_interval:
                billingCycle === "Monthly Billing" ? "monthly" : "yearly",
            })
          }
        >
          {currentPlan ? "Current Plan" : "Switch To Lite"}
        </LoadingButton>
      )}
    </Box>
  );
}

// -----------------------------------------------------------------
export default function SwitchMembership() {
  const theme = useTheme();
  const { user, subscription, membershipSwitch } = useAuth();
  const { showSubscriptionPlanSelector, setShowSubscriptionPlanSelector } =
    useCollapseDrawer();

  const billing_interval = user.subscription.payment_interval;

  const [billingInterval, setBillingInterval] = useState(billing_interval);
  const [switchInterval, setSwitchInterval] = useState(false);
  const [billingIntervalValue, setBillingIntervalValue] = useState("");
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const user_subcription = user.subscription;
  const [currentPlan, setCurrentPlan] = useState(user_subcription.plan_code);

  const isDesktop = useMediaQuery(theme.breakpoints.up("sm"));

  const handleChange = (event) => {
    const billing_value = event.target.value;
    setBillingInterval(billing_value);
  };

  useEffect(() => {
    if (billingInterval === "yearly") {
      setBillingIntervalValue("Yearly Billing");
    } else {
      setBillingIntervalValue("Monthly Billing");
    }

    if (billing_interval !== billingInterval) {
      setSwitchInterval(true);
    } else {
      setSwitchInterval(false);
    }
  }, [billingInterval, billing_interval]);

  const closeSubscriptionPlanSelector = () => {
    setShowSubscriptionPlanSelector(false);
  };

  const handleMembershipSwitch = async (data) => {
    try {
      const response = await membershipSwitch(data);
      if (response.scheduled_update === null) {
        localStorage.setItem("instantUpgrade", "true");
      } else {
        localStorage.setItem("futureUpgrade", "true");
      }
      window.location.reload();
    } catch (error) {
      setSnackbarMessage(
        "Sorry, we encountered a problem processing your payment. Please update your billing information."
      );
    }
  };

  return (
    <Drawer
      onClose={closeSubscriptionPlanSelector}
      open={showSubscriptionPlanSelector}
      anchor={isDesktop ? "right" : "bottom"}
      sx={{ zIndex: "9999" }}
      PaperProps={{
        sx: {
          borderRadius: isDesktop ? 0 : "20px 20px 0 0",
          width: isDesktop ? "600px" : "100%",
          height: isDesktop ? "100%" : "auto",
          maxHeight: isDesktop ? "100%" : "90vh",
          overflow: "auto",
          bgcolor: theme.palette.background.paper,
        },
      }}
    >
      <Box sx={{ height: '100%' }}>
        {/* Using our new CustomPricingTable component */}
        {subscription && subscription.subscription && (
          <CustomPricingTable 
            availablePlans={subscription.subscription.available_plans} 
            currentPlan={currentPlan}
            onClose={closeSubscriptionPlanSelector}
          />
        )}
      </Box>
      
      {snackbarMessage !== "" && (
        <SnackbarNotifier message={snackbarMessage} />
      )}
    </Drawer>
  );
}
